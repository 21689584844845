<template>
  <!-- modal的一个属性 destroyOnClose -->
  <a-modal
    :centered="true"
    :destroyOnClose="model == null ? true : false "
    :footer="null"
    :title="model ? '修改地址信息' : '添加地址信息'"
    :visible="visible"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="layout">
        <a-form-item label="联系人姓名">
          <a-input
            v-decorator="['userName',{rules: [{required: true, message: '请输入联系人姓名！'},
                                              {required: true,min:2,max:25, message: '请输入长度在2-25个字符之间，且不包含特殊字符'},
                                              {pattern: /[a-zA-Z\u4e00-\u9fa5][a-zA-Z0-9\u4e00-\u9fa5]$/, message: '输入的格式不正确' }
            ]}]"></a-input>
        </a-form-item>
        <a-form-item label="电话号码">
          <a-input v-decorator="['telNum', {rules:[{required: true, message: '请输入手机号码'},{ pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的手机号码' }]}]"></a-input>
        </a-form-item>
        <a-form-item label="州/省/地区">
          <a-cascader v-decorator="['area', {rules: [{required: true, message: '请选择州/省/地区'}]}]" :fieldNames="{ label: 'name', value: 'name', children: 'children' }" :options="options" placeholder="请选择州/省/地区" @change="onChange" />
          <!--          <AreaSelect v-decorator="['area', {rules: [{required: true, message: '请选择州/省/地区'}]}]"></AreaSelect>-->
        </a-form-item>
        <a-form-item label="详细地址">
          <a-input
            v-decorator="['detailInfo', {rules: [
              {required: true, message: '请输入详细地址'},
              {required: true,min:4,max:120, message: '请输入长度在4-120个字符之间，且不包含特殊字符'},
              {pattern: /^[\u4e00-\u9fa50-9A-Za-z,-@#+=!（）()]*$/, message: '输入的格式不正确' }]},
            ]"></a-input>
        </a-form-item>
        <a-form-item label="是否设为默认地址">
          <a-radio-group v-decorator="['isDefault', {initialValue:0,rules: [{required: true, message: '请选择是否设为默认地址'}]}]">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <div class="btn" @click="handleOk">
          保存地址
        </div>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { notification } from 'ant-design-vue'
import ShowModalMixin from '@/mixin/ShowModalMixin'
import AreaSelect from '@/components/AreaSelect'
import { addUserAddress, updateUserAddress, getRegionTree } from '@/api/Address'
import pick from 'lodash.pick'
import { mapGetters } from 'vuex'

const fields = ['userName', 'telNum', 'postalCode', 'detailInfo', 'isDefault', 'provinceName', 'cityName', 'countyName']
export default {
  name: 'AddressModal',
  components: { AreaSelect },
  props: {
    model: {
      type: Object,
      default: () => null
    }
  },
  mixins: [ ShowModalMixin ],
  data() {
    return {
      areaAddressId: '',
      options: [],
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
      },
      loading: false,
      form: this.$form.createForm(this),
      defaultArea: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  watch:{
    model(n,o){
      console.log('新--->',n)
      if(n == null){
        // 清空表单
        this.form.resetFields()
      }
      console.log('旧--->',o)
    },
  },
  created () {
    if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
      getRegionTree().then(res => {
        this.options = res.data
      })
      // 防止表单未注册
      fields.forEach(v => this.form.getFieldDecorator(v))

      // 当 model 发生改变时，为表单设置值
      this.$watch('model', () => {
        // 地区选择器数据较多需滞后
        this.$nextTick(() => {
          fields.forEach(v => this.form.getFieldDecorator(v))
          this.model && this.form.setFieldsValue(pick(this.model, fields))
          this.form.setFieldsValue({ area: [this?.model?.provinceName, this?.model?.cityName, this?.model?.countyName] })
        })
      })
    }
  },
  methods: {
    onChange(value, selectedOptions) {
      this.areaAddressId = selectedOptions[2].id
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = Object.assign(values, {
            provinceName: values.area[0],
            cityName: values.area[1],
            countyName: values.area[2],
            areaAddressId: this.areaAddressId
          })
          delete data.area
          console.log(data, 'data')
          if (this.model !== null) {
            updateUserAddress({ ...data, id: this.model.id }).then((res) => {
                notification.success({
                  message: res.msg,
                  description: res.msg,
                })
              this.$emit('ok')
              this.closeModal()
            })
          } else {
            console.log(data)
            addUserAddress(data).then((res) => {
              notification.success({
                message: res.msg,
                description: res.msg,
              })
              this.$emit('ok')
              this.closeModal()
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.btn{
  width: 50%;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color:#377de6;
  border-radius: 5px;
  cursor: pointer;
}

.active{
background-color: #00be92;
}
.noActive{
background-color: #eee;
}
</style>
