var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"centered":true,"destroyOnClose":_vm.model == null ? true : false,"footer":null,"title":_vm.model ? '修改地址信息' : '添加地址信息',"visible":_vm.visible},on:{"cancel":_vm.closeModal,"ok":_vm.handleOk}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.layout,false),[_c('a-form-item',{attrs:{"label":"联系人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['userName',{rules: [{required: true, message: '请输入联系人姓名！'},
                                              {required: true,min:2,max:25, message: '请输入长度在2-25个字符之间，且不包含特殊字符'},
                                              {pattern: /[a-zA-Z\u4e00-\u9fa5][a-zA-Z0-9\u4e00-\u9fa5]$/, message: '输入的格式不正确' }
            ]}]),expression:"['userName',{rules: [{required: true, message: '请输入联系人姓名！'},\n                                              {required: true,min:2,max:25, message: '请输入长度在2-25个字符之间，且不包含特殊字符'},\n                                              {pattern: /[a-zA-Z\\u4e00-\\u9fa5][a-zA-Z0-9\\u4e00-\\u9fa5]$/, message: '输入的格式不正确' }\n            ]}]"}]})],1),_c('a-form-item',{attrs:{"label":"电话号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['telNum', {rules:[{required: true, message: '请输入手机号码'},{ pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的手机号码' }]}]),expression:"['telNum', {rules:[{required: true, message: '请输入手机号码'},{ pattern: /^(?:(?:\\+|00)86)?1[3-9]\\d{9}$/, message: '请输入正确的手机号码' }]}]"}]})],1),_c('a-form-item',{attrs:{"label":"州/省/地区"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['area', {rules: [{required: true, message: '请选择州/省/地区'}]}]),expression:"['area', {rules: [{required: true, message: '请选择州/省/地区'}]}]"}],attrs:{"fieldNames":{ label: 'name', value: 'name', children: 'children' },"options":_vm.options,"placeholder":"请选择州/省/地区"},on:{"change":_vm.onChange}})],1),_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['detailInfo', {rules: [
              {required: true, message: '请输入详细地址'},
              {required: true,min:4,max:120, message: '请输入长度在4-120个字符之间，且不包含特殊字符'},
              {pattern: /^[\u4e00-\u9fa50-9A-Za-z,-@#+=!（）()]*$/, message: '输入的格式不正确' }]},
            ]),expression:"['detailInfo', {rules: [\n              {required: true, message: '请输入详细地址'},\n              {required: true,min:4,max:120, message: '请输入长度在4-120个字符之间，且不包含特殊字符'},\n              {pattern: /^[\\u4e00-\\u9fa50-9A-Za-z,-@#+=!（）()]*$/, message: '输入的格式不正确' }]},\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"是否设为默认地址"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isDefault', {initialValue:0,rules: [{required: true, message: '请选择是否设为默认地址'}]}]),expression:"['isDefault', {initialValue:0,rules: [{required: true, message: '请选择是否设为默认地址'}]}]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 否 ")])],1)],1),_c('div',{staticClass:"btn",on:{"click":_vm.handleOk}},[_vm._v(" 保存地址 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }