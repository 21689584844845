/**
 * @author  XuHongli
 * @date  2022/9/2 14:33
 * @version 1.0
 * @description 地址 Api
 */

import request from '@/utils/axios'

export function getUserAddressObj (id) {
  return request({
    url: '/supplyportal/useraddress/' + id,
    method: 'get',
  })
}

// 一键下载商品详情资源包
export function downloadZipFile (id) {
  return request({
    url:'/supplyportal/supplyproduct/downloadZipFile/'+id,
    method: 'get',
    responseType: 'blob',
  })
}

export function getUserAddress (parameter) {
  return request({
    url: '/supplyportal/useraddress/page',
    method: 'get',
    params: parameter
  })
}

export function addUserAddress(data) {
  return request({
    url: '/supplyportal/useraddress',
    method: 'post',
    data
  })
}

export function updateUserAddress(data) {
  return request({
    url: '/supplyportal/useraddress',
    method: 'put',
    data
  })
}

export function deleteUserAddress(id) {
  return request({
    url: '/supplyportal/useraddress/' + id,
    method: 'delete',
  })
}

export function getDefaultAddress() {
  return request({
    url: '/supplyportal/useraddress/getDefaultAddress',
    method: 'get'
  })
}

// 三级联地区接口
export function getRegionTree() {
  return request({
    url: '/supplyportal/region/tree',
    method: 'get'
  })
}
