/**
 * @author  XuHongli
 * @date  2022/9/2 20:56
 * @version 1.0
 * @description 地址表格字段数据
 */
export const columns = [
  {
    title: '编号',
    dataIndex: 'id',
  },
  {
    title: '收货人',
    dataIndex: 'userName'
  },
  {
    title: '联系电话',
    dataIndex: 'telNum'
  },
  {
    title: '地址',
    dataIndex: 'address',
    scopedSlots: { customRender: 'address' }
  },
  {
    title: '默认地址',
    dataIndex: 'isDefault',
    scopedSlots: { customRender: 'isDefault' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '100px',
    scopedSlots: { customRender: 'action' }
  }
]
