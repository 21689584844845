<template>
  <div class="tabButtonGroup">
    <div v-for="(item,index) in menu" :key="index" :class="[index === selectIndex ? 'active' : '']" class="btn" @click="handleClick(item.value,index)">{{ item.label }}</div>
    <!-- <a-button-group>
      <a-button
        class="tabButton"
        :type="item.value === value ? 'danger' : ''"
        v-for="(item,index) in menu"
        :key="index"
        @click="handleClick(item.value)">
        {{ item.label }}
      </a-button>
    </a-button-group> -->
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/31 16:24
 * @version 1.0
 * @description 标签按钮组
 */
export default {
  name: 'TabButtonGroup',
  props: {
    /**
     * @description 按钮菜单数据
     * @example [{ label: String, value: String | Number }]
     */
    menu: {
      type: Array,
      required: true,
    },
    defaultValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      required: true
    }
  },
  data() {
    return {
      value: this.defaultValue,
      selectIndex:0
    }
  },
  mounted() {
    console.log('mounted',this.defaultValue )
    if (this.defaultValue !== ''){
      let index = this.menu.findIndex(item => item.value == this.defaultValue)
      this.selectIndex = index > -1 ? index : 0
    }
  },
  methods: {
    handleClick(val,index) {
      this.selectIndex = index
      this.value = val
      this.$emit('input', val)
    }
  },
}
</script>

<style lang="scss" scoped>
.tabButtonGroup {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #eee;
  .btn{
    flex: 1;
    width: 140px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  .active{
    color: #df3028;
    background: #fff;
  }
  // .tabButton {
  //   background-color: white;
  //   color: #0a0d10;
  //   border-color: transparent;
  //   border-radius: 0;
  //   font-weight: 400;
  //   box-shadow: 0 2px 4px rgb(0 0 0 / 11%);
  //   &:after {
  //     box-shadow: 0 0 0 0 rgb(232, 155, 79);
  //   }
  //   &:hover {
  //     color: rgb(232, 155, 79) ;
  //     box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
  //   }
  // }
  // .tabButton.ant-btn-danger {
  //   background-color: rgb(232, 155, 79);
  //   color: white;
  // }
}
</style>
